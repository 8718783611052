import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FAQsSection from "./faqs";

export default function MicrorecLowerpart() {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ paddingTop: "0px" }}>
        <Typography
          variant="body1"
          paragraph={true}
          gutterBottom={true}
          sx={{ textAlign: "right", fontSize: "18px", padding: "10px" }}
        >
          Need help?{" "}
          <a
            className="link_contact"
            href="/contact-us"
            target="_blank"
            style={{ color: "#8F4FFF", fontWeight: "bold" }}
          >
            Contact us
          </a>
        </Typography>
        <hr></hr>
      </Grid>
      <Grid container sx={{ marginTop: "2em" }} style={{ paddingLeft: "20px" }}>
        <Grid item xs={12} sm={4}>
          <Typography
            variant={"h5"}
            color={"primary"}
            sx={{ paddingBottom: 2, fontWeight: "bold" }}
          >
            Hardware Specifications
          </Typography>
        </Grid>
        <Grid
          item
          xs={11.7}
          sm={7.5}
          sx={{
            border: "1px solid black",
            borderRadius: "10px",
            padding: "10px",
            textAlign: "center",
          }}
        >
          <Grid container>
            <Grid xs={3}>
              <div style={{ fontWeight: "600" }}>Max Widht</div>
              <div>119,5mm</div>
            </Grid>
            <Grid xs={3}>
              <div style={{ fontWeight: "600" }}>Max Height</div>
              <div>255,5mm</div>
            </Grid>
            <Grid xs={3}>
              <div style={{ fontWeight: "600" }}>Min Height</div>
              <div>211,5mm</div>
            </Grid>
            <Grid xs={3}>
              <div style={{ fontWeight: "600" }}>Weight</div>
              <div>250mg</div>
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "2em" }}>
            <Grid xs={3}>
              <div style={{ fontWeight: "600" }}>Min Width</div>
              <div>110mm</div>
            </Grid>
            <Grid xs={3}>
              <div style={{ fontWeight: "600" }}>Min Depth</div>
              <div>94mm</div>
            </Grid>
            <Grid xs={3}>
              <div style={{ fontWeight: "600" }}>Max Depth</div>
              <div>110mm</div>
            </Grid>
            <Grid xs={3}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <FAQsSection />
    </Grid>
  );
}
